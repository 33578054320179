@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper .swiper-slide {
  height: auto;
}

.swiper-slide img {
  display: block;
  object-fit: fill;
}

.mainBanner .swiper-slide img {
  width: 100%;
  height: auto;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.mainBanner .swiper-pagination-bullets {
  bottom: 10px !important;
}

.product-gallery .swiper-pagination-bullets,
.review-swiper .swiper-pagination-bullets,
.rating-swiper .swiper-pagination-bullets,
.mamapedia-video-list .swiper-pagination-bullets {
  bottom: 0 !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background-color: #EAEAEA !important;
  opacity: 100 !important;
  border-radius: 100% !important;
  margin: 0 10px !important;
}

.swiper-button-prev {
  width: 32px !important;
  height: 32px !important;
  background-image: url(https://static.mooimom.id/images/icons/circle-left-arrow.png);
  background-size: cover;
  background-position: center;
}

.swiper-button-next {
  width: 32px !important;
  height: 32px !important;
  background-image: url(https://static.mooimom.id/images/icons/circle-right-arrow.png);
  background-size: cover;
  background-position: center;
}

.swiper-button-prev:after, .swiper-button-next:after {
  content: "" !important;
}

@media (max-width: 760px) {
  .review-swiper .swiper-pagination-bullet {
    background-color: #EAEAEA !important;
    margin: 0 6px !important;
  }

  .review-swiper .swiper-pagination-bullet-active {
    background-color: #89D1CB !important;
  }

  .product-gallery .swiper-pagination-bullet {
    width: 5px !important;
    height: 5px !important;
    background-color: #D9D9D9 !important;
    opacity: 100 !important;
    border-radius: 100% !important;
    margin: 0 4px !important;
  }

  .product-gallery .swiper-pagination-bullet-active {
    background-color: #89D1CB !important;
    width: 24px !important;
    border-radius: 16px !important;
  }
}

.swiper-pagination-bullet-active {
  background-color: #89D1CB !important;
}

.mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 28vh;
  height: auto;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.top-banner-mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 28vh;
  height: auto;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.top-text-mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 28vh;
  height: auto;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.mamapedia-mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 64px;
  height: auto;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.mega-menu-height {
  height: calc(100% - 162px);
}

.sub-mega-menu-height {
  height: calc(100% - 65px);
}

/* #hoverable Class Styles */
.hoverable {
  position: static;
}

.hoverable:hover .mega-menu,
.hoverable:hover .top-banner-mega-menu,
.hoverable:hover .top-text-mega-menu,
.hoverable:hover .mamapedia-mega-menu {
  display: block;
}

.sub-category-height {
  height: calc(100% - 50px);
}

.video-banner {
  height: calc(100vh - 73px);
}

/* flex shrink */
.our-values {
  height: 563px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.value {
  flex: 1;
  overflow: hidden;
  transition: 1s;
}

.value:hover {
  flex: 1 1 40%;
}

.value > img, .value > a > img {
  height: 563px;
  object-fit: cover;
  transition: 1s;
}

.value:hover > img {
  height: 563px;
}

.rotate-vertical {
  position: absolute;
  z-index: 5;
  top: 30px;
  right: 0;
  writing-mode: vertical-lr;
}

@media (max-width: 760px) {
  .value > a > img, .value:hover > a > img {
    height: 335px;
  }

  .rotate-vertical {
    top: 14px;
  }
}

/* modal */
.react-confirm-alert-overlay {
  background-color: rgba(89, 89, 89, 0.4) !important;
}

@media (max-width: 760px) {
  .react-confirm-alert {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .react-confirm-alert-overlay {
    background: rgba(40, 40, 41, 0.5) !important;
  }

  .react-confirm-alert-body {
    width: 100% !important;
    border-radius: 20px 20px 0 0 !important;
  }
}

.cart-list-height {
  height: calc(100% - 132px);
}

@media (max-width: 760px) {
  .cart-list-height {
    height: calc(100% - 86px);
  }

  .cart-product-height {
    height: calc(100% - 120px);
  }
}

.size-guide-height {
  height: calc(100% - 58px);
}

.search-height {
  height: calc(100% - 120px);
}

@media (max-width: 760px) {
  .search-height {
    height: calc(100% - 58px);
  }
}

.float-video > div > video {
  border-radius: 8px;
}

/* scrollbar */
/* width, height */
/* ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  background-clip: content-box;
  border: 5px solid transparent;
} */

/* hide default select arrow */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* mini product */
.mini-product:hover > img.product-img:first-child {
  opacity: 0;
}

.mini-product:hover > img.product-img:last-child {
  opacity: 100;
}

/* homepage review */
.review-item > div:last-child {
  z-index: 0;
  transform: translateY(120%);
}

.review-item:hover > div:last-child {
  z-index: 10;
  transform: translateY(0);
}

.select-voucher {
  width: 100%;
  height: 32px;
  max-height: 32px;
  /* box-shadow: 0px 0px 0px 2px white inset; */
  position: relative;
}

.select-voucher::before {
  content: "";
  position: absolute;
  top: 80%;
  left: 25%;
  width: 20%;
  height: 2px;
  background-color: white;
  transform-origin: right;
  transform: rotate(45deg) translateY(-50%);
}

.select-voucher::after {
  content: "";
  position: absolute;
  top: 80%;
  right: 23%;
  width: 30%;
  height: 2px;
  background-color: white;
  transform-origin: left;
  transform: rotate(-45deg) translateY(-50%);
}

.voucher-detail {
  height: calc(100dvh - 100px);
}

.react-datepicker-wrapper {
  width: 100%;
}

.menu-arrow {
  width: 16px;
  height: 16px;
  /* box-shadow: 0px 0px 0px 2px white inset; */
  position: relative;
}

.menu-arrow::before {
  content: "";
  position: absolute;
  top: 70%;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: white;
  transform-origin: right;
  transform: rotate(45deg) translateY(-50%);
}

.menu-arrow::after {
  content: "";
  position: absolute;
  top: 70%;
  right: 0;
  width: 50%;
  height: 2px;
  background-color: white;
  transform-origin: left;
  transform: rotate(-45deg) translateY(-50%);
}

/* hide search input clear icon and decorations */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

/* mamapedia article */
.mamapedia-article .mamapedia-content h1 {
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: bold;
}

.mamapedia-article .mamapedia-content h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 12px;
}

.mamapedia-article .mamapedia-content h3,
.mamapedia-article .mamapedia-content h3 span {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #5E5E5E !important;
  margin-bottom: 5px;
}

.mamapedia-article .mamapedia-content h4 {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
}

.mamapedia-article .mamapedia-content h4 a,
.mamapedia-article .mamapedia-content h4 a * {
  font-size: 13px;
  line-height: 20px;
  color: #6dccbc !important;
  font-weight: 700;
  font-style: italic;
}

.mamapedia-article .mamapedia-content p,
.mamapedia-article .mamapedia-content p span,
.mamapedia-article .mamapedia-content ul li *,
.mamapedia-article .mamapedia-content ol li *,
.mamapedia-article .mamapedia-content ul li,
.mamapedia-article .mamapedia-content ol li {
  font-size: 15px;
  line-height: 1.4;
  text-align: left;
  color: #5E5E5E !important;
}

.mamapedia-article .mamapedia-content p {
  margin-bottom: 16px;
}

.mamapedia-article .mamapedia-content a {
  color: #6dccbc;
}

.mamapedia-article .mamapedia-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #E4E4E4;
}

.mamapedia-article .mamapedia-content img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 20px;
}

.mamapedia-article .mamapedia-content .desktop-banner {
  display: block;
}

.mamapedia-article .mamapedia-content .mobile-banner {
  display: none;
}

.mamapedia-article .mamapedia-content iframe {
  width: 100%;
  height: 360px;
}

@media (max-width: 760px) {
  .mamapedia-article .mamapedia-content h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mamapedia-article .mamapedia-content h2 {
    font-size: 20px;
  }

  .mamapedia-article .mamapedia-content h3,
  .mamapedia-article .mamapedia-content h3 span {
    font-size: 16px;
  }

  .mamapedia-article .mamapedia-content h4 {
    font-size: 18px;
  }

  .mamapedia-article .mamapedia-content h4 a,
  .mamapedia-article .mamapedia-content h4 a * {
    font-size: 13px;
  }

  .mamapedia-article .mamapedia-content p,
  .mamapedia-article .mamapedia-content p span,
  .mamapedia-article .mamapedia-content ul li *,
  .mamapedia-article .mamapedia-content ol li *,
  .mamapedia-article .mamapedia-content ul li,
  .mamapedia-article .mamapedia-content ol li {
    font-size: 13px;
  }

  .mamapedia-article .mamapedia-content .desktop-banner {
    display: none;
  }

  .mamapedia-article .mamapedia-content .mobile-banner {
    display: block;
  }

  .mamapedia-article .mamapedia-content iframe {
    height: 260px;
  }
}

.journey-hide {
  animation: journey-hide 1s normal forwards;
}

.journey-display {
  animation: journey-display 1s normal forwards;
}

@keyframes journey-hide {
  0% {
    opacity: 1;
    height: auto;
  }

  50% {
    height: 0;
    opacity: 0.3;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes journey-display {
  0% {
    opacity: 0;
    height: 0;
  }

  50% {
    height: auto;
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    height: auto;
  }
}

.sdg-container {
  height: 700px;
  overflow: hidden;
}

.sdg-img-hide {
  animation: sdg-img-hide 1s normal forwards;
}

.sdg-img-display {
  animation: sdg-img-display 1s normal forwards;
}

.sdg-img-show {
  top: -60px;
  right: -60px;
  transform: scale(1.1);
}

.sdg-container .overlay {
  position: absolute;
  z-index: 3;
  background-color: #035C6770;
  opacity: 1;
  transition: opacity 0.5s ease;
}

@keyframes sdg-img-hide {
  from {
    transform: translate(60px, -60px) scale(1.1);
  }

  to {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes sdg-img-display {
  from {
    transform: translate(0, 0) scale(1);
  }

  to {
    transform: translate(60px, -60px) scale(1.1);
  }
}

.sdg-wording-hide {
  animation: sdg-wording-hide 1s normal forwards;
}

.sdg-wording-display {
  animation: sdg-wording-display 1s normal forwards;
}

@keyframes sdg-wording-hide {
  0% {
    opacity: 1;
    height: auto;
    z-index: 1;
  }

  5% {
    height: 0;
    opacity: 0.3;
    z-index: 0;
  }

  100% {
    opacity: 0;
    height: 0;
    z-index: 0;
  }
}

@keyframes sdg-wording-display {
  0% {
    opacity: 0;
    height: 0;
    z-index: 0;
  }

  5% {
    height: auto;
    opacity: 0.5;
    z-index: 1;
  }

  100% {
    opacity: 1;
    height: auto;
    z-index: 1;
  }
}

.brand-container {
  display: flex;
  box-sizing: border-box;
  height: 563px;
}

.box {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
  border-radius: 16px;
  position: relative;
}

.box > img {
  width: 100%;
  height: 563px;
  object-fit: cover;
  transition: 0.5s;
  border-radius: 16px;
}

.box > .brand-value {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 0;
  writing-mode: vertical-lr;
}

.box > .brand-content {
  display: hidden;
}

.box:hover > .brand-content {
  display: block;
  position: absolute;
  z-index: 10;
  left: 51px;
  bottom: 50px;
}

.box:hover {
  flex: 1 1 50%;
}
.box:hover > img {
  width: 100%;
  height: 563px;
}

.brand-value-mobile {
  position: absolute;
  z-index: 10;
  top: 12px;
  right: 0;
  writing-mode: vertical-lr;
}

.ig-container .ig-story .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #035C6770;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.ig-container .ig-story:hover .overlay {
  opacity: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Add to cart */
.add-to-cart-attribute-height {
  height: calc(50dvh - 100px);
}

/* Blur Product animation */
.blur-product-img {
  background-size: 150%;

  -webkit-animation: BluProductAnimate 2s ease infinite;
  -moz-animation: BluProductAnimate 2s ease infinite;
  animation: BluProductAnimate 2s ease infinite;
}

@keyframes BluProductAnimate {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
